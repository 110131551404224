<script lang="ts" setup>
import type { GameOfWeek } from "@/types";

defineProps<{
	gameOfWeek?: GameOfWeek;
}>();
defineEmits<{ (event: "click"): void }>();

const { t } = useT();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
</script>
<template>
	<div class="game-week" @click="$emit('click')">
		<NuxtImg
			class="image"
			:src="`${baseImageUrl}${gameOfWeek?.imageBanner2x}`"
			sizes="md:208px lg:416px"
			format="avif"
			width="416"
			height="340"
			alt="game-of-week"
		/>
		<div class="badges">
			<MGameBadge variant="new" />
			<MGameBadge v-if="gameOfWeek?.isExclusive" variant="exclusive" />
		</div>
		<div class="title" v-html="gameOfWeek?.bannerTitle" />
		<AButton variant="primary">
			<AText :modifiers="['uppercase', 'semibold']">{{ t("Play Now") }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.game-week {
	.image {
		position: absolute;
		right: 0;
		top: 0;
		width: 208px;
		height: 170px;
	}

	.badges {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		gap: gutter(1);

		&:deep(.text) {
			font-size: 12px;
		}
	}

	.title {
		z-index: 1;

		&:deep(div) {
			font-weight: 700;
			margin-top: gutter(0.625);
		}
	}

	button {
		margin-top: auto;
		max-width: 165px;
		height: 36px;
	}
}
</style>
